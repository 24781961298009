<template>
  <div>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="0"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateValue"
          prepend-inner-icon="mdil-calendar"
          :label="label"
          readonly
          :disabled="disabled"
          :single-line="singleLine"
          dense
          outlined
          :class="classes"
          :clearable="clearable"
          :hide-details="hideDetails"
          :error-messages="errors"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="dateValue"
        :active-picker.sync="activePicker"
        :max="max"
        :min="min"
        :range="range"
        :no-title="noTitle"
        @input="dateMenu = false"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: [Number, String, Array], default: null },
    label: { type: String, default: '' },
    classes: { type: String, default: '' },
    hideDetails: { type: Boolean, default: false },
    singleLine: { type: Boolean, default: false },
    noTitle: { type: Boolean, default: false },
    failed: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    range: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    min: { type: String, default: '' },
    isDob: { type: Boolean, default: false },
    maxDate: { type: undefined, default: null },
  },

  data() {
    return {
      dateMenu: false,
      dateValue: null,
      activePicker: null,
      max: '',
    }
  },

  watch: {
    dateMenu(val) {
      if (this.isDob) {
        this.max = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      } else {
        this.max = this.maxDate ?? ''
        val && setTimeout(() => (this.activePicker = 'DATE'))
      }
    },

    dateValue() {
      this.$emit('input', this.dateValue)
    },

    value() {
      this.dateValue = this.value
    }
  },

  mounted() {
    this.dateValue = this.value
  },
}
</script>

<style lang="scss" scoped>
</style>