<template>
  <!-- <div> -->
  <!-- <tr> -->
  <th
    colspan="100"
    class="d-none d-print-table-cell px-0"
  >
    <img
      src="../../assets/invoice_header_image.jpg"
      width="100%"
      height="160px"
      alt=""
    >
  </th>
  <!-- </tr> -->
  <!-- </div> -->
</template>

<script>
export default {
  // props: {
  //   date: { type: String, default: new Date() },
  //   invoiceNo: { type: String, default: null },
  // },
};
</script>

<style lang="scss" scoped></style>
