import Vue from "vue";
import { mapGetters } from "vuex";
import store from "../store/index.js";
import Confirmation from "../components/Global/Confirmation.vue";
import router from "../router";
import vuetify from "../plugins/vuetify";
import i18n from "../plugins/i18n";

export default {
  methods: {
    $_sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    $_get_image(imageName) {
      return require(`/src/assets/images/${imageName}`);
    },

    $_auth_token() {
      return store.getters["accessToken"];
    },

    $_get_file_extension(fileName) {
      return fileName.split(".").pop().toLowerCase();
    },

    $_is_image(fileName) {
      const imgArray = ["jpg", "png", "jpeg"];
      return imgArray.includes(this.$_get_file_extension(fileName));
    },

    $_is_pdf(fileName) {
      const pdfArray = ["pdf"];
      return pdfArray.includes(this.$_get_file_extension(fileName));
    },

    $_is_doc(fileName) {
      const docArray = ["doc", "docx"];
      return docArray.includes(this.$_get_file_extension(fileName));
    },

    $_is_excel(fileName) {
      const excelArray = ["xls", "xlsx", "csv"];
      return excelArray.includes(this.$_get_file_extension(fileName));
    },

    $_input_color() {
      return "#409eff";
    },

    $_profile_placeholder() {
      return this.$_get_image("profile_placeholder.png");
    },

    $_file_path() {
      return process.env.VUE_APP_BASE_URL;
    },

    $_notify(message, type = "success", timeout = 3000) {
      store.commit("SET_NOTIFICATION", {
        message: i18n.t(message),
        type: type,
        timeout: timeout,
      });
    },

    $_section_title(data) {
      store.commit("SET_SECTION_TITLE", data);
    },

    $_number_format(number) {
      if (!number) return 0;

      number = Number(number);
      if (isNaN(number)) return number;

      return number.toLocaleString();
    },

    $_date_for_picker(date = null) {
      let dateObj = date ?? new Date();
      return Vue.moment(dateObj).format("YYYY-MM-DD");
    },

    $_date_format(date) {
      return Vue.moment(date).format("DD/MM/YYYY");
    },

    $_date_time_format(date) {
      return Vue.moment(date).format("DD/MM/YYYY hh:mm a");
    },

    $_round_to_nearest_number(number, nearestNumber = 250) {
      return nearestNumber * Math.round(number / nearestNumber);
    },

    $_has_perm(permission) {
      if (typeof this.authUserPermissions === "string") {
        if (this.authUserPermissions === "superuser") {
          return true;
        }
      } else {
        for (const index in this.authUserPermissions) {
          if (permission === this.authUserPermissions[index].codename) {
            return true;
          }
        }
      }
      return false;
    },

    $_has_perms(permissions) {
      if (typeof this.authUserPermissions === "string") {
        if (this.authUserPermissions === "superuser") {
          return true;
        }
      } else {
        for (const i in permissions) {
          for (const index in this.authUserPermissions) {
            if (permissions[i] === this.authUserPermissions[index].codename) {
              return true;
            }
          }
        }
      }
      return false;
    },

    $_confirm(propObject = { type: "delete" }) {
      if (propObject.type == "delete") {
        propObject = {
          title: propObject.title ?? "Warning",
          titleColor: propObject.titleColor ?? "error--text",
          message:
            propObject.message ?? "This action delete the data permanently.",
          confirmBtnText: propObject.confirmBtnText ?? "Delete",
          confirmBtnIcon: propObject.confirmBtnIcon ?? "mdil-delete",
          confirmBtnColor: propObject.confirmBtnColor ?? "error",
          cancelBtnText: propObject.cancelBtnText ?? "Cancel",
        };
      } else if (propObject.type == "approve") {
        propObject = {
          title: propObject.title ?? "Are you sure ?",
          titleColor: propObject.titleColor ?? "",
          message: propObject.message ?? "",
          confirmBtnText: propObject.confirmBtnText ?? "Yes",
          confirmBtnIcon: propObject.confirmBtnIcon ?? "mdil-check",
          confirmBtnColor: propObject.confirmBtnColor ?? "primary",
          cancelBtnText: propObject.cancelBtnText ?? "Cancel",
        };
      }

      vuetify.preset.rtl = this.$vuetify.rtl;

      return new Promise((resolve) => {
        const Wrapper = Vue.extend(Confirmation);
        const dialog = new Wrapper({
          propsData: {
            propObject,
          },
          router,
          store,
          vuetify,
          i18n,
        }).$mount();

        dialog.$on("close", (value) => {
          dialog.$destroy();
          dialog.$el.remove();
          resolve(value);
        });
      });
    },

    $_print(id = "print-area") {
      return new Promise((resolve) => {
        let theme = this.$vuetify.theme.isDark;
        this.$vuetify.theme.isDark = false;

        setTimeout(() => {
          const prtHtml = document.getElementById(id).innerHTML;
          // Get all stylesheets HTML
          let stylesHtml = "";
          for (const node of [
            ...document.querySelectorAll('link[rel="stylesheet"], style'),
          ]) {
            stylesHtml += node.outerHTML;
          }

          let vuetifyClasses = [
            ...document.querySelector("#app").classList,
            ...document.querySelector(".v-data-table").classList,
            ...document.querySelector(".v-application").classList,
          ];

          // if (document.querySelector('.v-data-table')) {
          //   vuetifyClasses.push(...document.querySelector('.v-data-table').classList)
          // }

          vuetifyClasses = vuetifyClasses
            .toString()
            .replaceAll(",", " ")
            .replace("v-data-table--fixed-header", "");

          const WinPrint = window.open(
            "",
            "",
            "left=300px,top=0px,width=1000,height=1000,toolbar=0,scrollbars=0,status=0"
          );

          WinPrint.document.write(`
          <!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}

              <style>
                .v-data-table__wrapper {
                  width: 100%
                }

                #invoice-info {
                  display: table-cell !important
                }
              </style>
            </head>
            <body class="${vuetifyClasses}">
              ${prtHtml}
            </body>
          </html>
        `);

          this.$vuetify.theme.isDark = theme;
          WinPrint.document.close();

          setTimeout(() => {
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
            resolve();
          }, 500);
        }, 100);
      });
    },

    $_manual_print(content) {
      return new Promise((resolve) => {
        let theme = this.$vuetify.theme.isDark;
        this.$vuetify.theme.isDark = false;

        setTimeout(() => {
          // Get all stylesheets HTML
          let stylesHtml = "";
          for (const node of [
            ...document.querySelectorAll('link[rel="stylesheet"], style'),
          ]) {
            stylesHtml += node.outerHTML;
          }

          let vuetifyClasses = [
            ...document.querySelector("#app").classList,
            ...document.querySelector(".v-application").classList,
          ];

          vuetifyClasses = vuetifyClasses.toString().replaceAll(",", " ");

          const WinPrint = window.open(
            "",
            "",
            "left=300px,top=0px,width=800,height=900,toolbar=0,scrollbars=0,status=0"
          );

          WinPrint.document.write(`
            <!DOCTYPE html>
            <html>
              <head>
                ${stylesHtml}
                
                <style>
                  ${content.css ?? ""}
                </style>
              </head>
              <body class="${vuetifyClasses}">
                ${content.content}
              </body>
            </html>
          `);

          this.$vuetify.theme.isDark = theme;

          WinPrint.document.close();
          setTimeout(() => {
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
            resolve();
          }, 500);
        }, 100);
      });
    },
  },

  computed: {
    ...mapGetters(["authUserData", "authUserPermissions"]),
  },
};
